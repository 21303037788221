.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 20;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-color: var(--global__color_primary);
  transition: opacity 1s ease-out;
}

.loading-screen {
  background: linear-gradient(
    180deg,
    var(--global__color_primary_opacity),
    var(--global__color_primary)
  );
  background-size: 400% 400%;

  animation: load 3s ease infinite;
}

.loading-screen.hidden {
  opacity: 0;
  pointer-events: none;
}

.loading-screen .spinner {
  margin-top: auto;
  padding-bottom: var(--global__padding_standard);
  width: 6rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.loading-screen .spinner div {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: var(--global__color_secondary);
}

.loading-screen .spinner div:nth-of-type(1) {
  transform: translateX(-100%);
  animation: left-swing 0.75s ease-in alternate infinite;
}

.loading-screen .spinner div:nth-of-type(3) {
  transform: translateX(-95%);
  animation: right-swing 0.75s ease-out alternate infinite;
}

@keyframes left-swing {
  50%,
  100% {
    transform: translateX(95%);
  }
}

@keyframes right-swing {
  50% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes load {
  0% {
    background-position: 0% 77%;
  }
  50% {
    background-position: 100% 24%;
  }
  100% {
    background-position: 0% 77%;
  }
}
