footer {
  padding: var(--global__padding_standard);
  padding-bottom: calc(var(--global__padding_standard) / 2);
  font-family: var(--global__font-family_body);
  color: var(--global__color_secondary);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  bottom: 0;
  width: calc(100% - var(--global__padding_standard) * 2);
  height: calc(
    var(--footer__height_main) - var(--global__padding_standard) * 1.5
  );
  height: -webkit-fill-available;
  background: var(--footer__color_background);
}

footer h2 {
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

footer h3 {
  font-size: 3rem;
  margin-bottom: 0.25rem;
}

footer p,
footer td {
  font-size: 1.4rem;
}

footer td {
  margin-bottom: 0.5rem;
  vertical-align: middle;
}

footer table {
  table-layout: fixed;
}

footer tbody td {
  padding-bottom: 0.3rem;
}

footer tbody td:first-child {
  width: 1px;
  padding-right: 1rem;
  white-space: nowrap;
}

footer tbody td:first-child {
  width: 1px;
  white-space: nowrap;
}

footer tbody tr:last-child td {
  padding-bottom: 0rem;
}

footer tbody tr:last-child:hover a {
  font-weight: bold;
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 767px) {
  footer h2 {
    font-size: 2.9rem;
    margin-bottom: 0.25rem;
  }

  footer h3 {
    font-size: 1.9rem;
    margin-bottom: 0.25rem;
  }

  footer p,
  footer td {
    font-size: 1rem;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 812px) and (orientation: landscape) {
  footer h2 {
    font-size: 2.5rem;
  }

  footer h3 {
    font-size: 2rem;
  }

  footer p,
  footer td {
    font-size: 0.9rem;
  }
}

/* 
  ##Device = Smallest
*/

@media (max-width: 568px) and (orientation: landscape) {
  footer h2 {
    font-size: 2rem;
  }

  footer h3 {
    font-size: 1.6rem;
  }

  footer p,
  footer td {
    font-size: 0.8rem;
  }
}

/* 
  ##Device = Smallest
*/
@media (max-width: 360px) {
  footer h2 {
    font-size: 2.5rem;
    margin-bottom: 0.125rem;
  }

  footer h3 {
    font-size: 1.5rem;
    margin-bottom: 0.0625rem;
  }

  footer p,
  footer td {
    font-size: 0.9rem;
  }
}
