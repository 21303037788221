:root {
  --color-white: #fff;
  --color-white-70: rgb(255 255 255 / 70%);
  --color-white-50: rgb(255 255 255 / 50%);
  --color-white-40: rgb(255 255 255 / 30%);
  --color-beige-light: #f5f8f6;
  --color-beige-middle: #eef2ef;
  --color-beige-dark: #e2e5e3;
  --color-line: #d5d7d6;
  --color-line-dark: #b1b4b2;
  --color-primary-dark: #011f00;
  --color-primary-middle: #023300;
  --color-secondary-dark: #4bb616;
  --color-secondary-middle: #81e151;
  --color-secondary-light: #a0e87d;
  --color-black: #252525;
  --color-text-40: #999c99;
  --color-text-50: #6a6c6a;
  --color-text-70: #303330;
  --color-text-100: #222522;
  --color-error: #dd4f43;
  --color-accent-middle: #fe996b;
  --color-accent-dark: #f58273;

  /* Assuming 1 rem = 16px */

  /** Radius */
  --radius-xs: 0.25rem; /* Was 4px */
  --radius-s: 0.5rem; /* Was 8px */
  --radius-m: 1rem; /* Was 16px */
  --radius-l: 1.5rem; /* Was 24px */
  --radius-xl: 2rem; /* Was 32px */
  --radius-2xl: 3rem; /* Was 48px */
  --radius-full: 62.4375rem; /* Was 999px */

  /** Spacing */
  --spacing-2xs: 0.125rem; /* 2px */
  --spacing-xs: 0.25rem; /* 4px */
  --spacing-s: 0.5rem; /* 8px */
  --spacing-m: 0.75rem; /* 12px */
  --spacing-l: 1rem; /* 16px */
  --spacing-xl: 1.5rem; /* 24px */
  --spacing-2xl: 2rem; /* 32px */
  --spacing-3xl: 3rem; /* 48px */
  --spacing-4xl: 3.5rem; /* 56px */
  --spacing-5xl: 4rem; /* 64px */
  --spacing-6xl: 6rem; /* 96px */
  --spacing-7xl: 7.75rem; /* 124px */
  --spacing-8xl: 15rem; /* 240px */

  /* Responsive */
  --spacing-wide-border: var(--spacing-xl);

  /* Easings */
  --easing-linear-150: 150ms linear;
  --easing-linear-100: 100ms linear;
  --easing-linear-50: 50ms linear;
  --easing-entrance: 150ms cubic-bezier(0, 0, 0.3, 1);
  --easing-exit: 150ms cubic-bezier(0.4, 0.14, 1, 1);
  --easing-standard: 250ms cubic-bezier(0.4, 0.14, 0.3, 1);
  --easing-slider: 500ms cubic-bezier(0.4, 0.14, 0.3, 1);

  /* Top Radius */
  --overlap: var(--spacing-l);
  --radius-top: var(--overlap) var(--overlap) 0 0;
  --radius-top-overlap: calc(var(--overlap) * -1);
}

/* Tablet styles */
@media (width >= 640px) {
  /* TODO */
  :root {
    --spacing-wide-border: var(--spacing-2xl);
  }
}

/* Desktop styles */
@media (width >= 1280px) {
  :root {
    --overlap: var(--spacing-xl);
    --spacing-wide-border: 290px;
  }
}

/* Desktop XL styles */
@media (width >= 1920px) {
  :root {
    --overlap: var(--spacing-2xl);
    --spacing-wide-border: 484px;
  }
}

#cc-main {
  --cc-font-family: var(--global__font-family_body);
  --cc-toggle-on-bg: var(--color-secondary-light);
  --cc-toggle-off-bg: var(--color-beige-dark);
  --cc-cookie-category-block-bg: var(--color-white);

  .toggle__icon-on,
  .toggle__icon-off {
    svg {
      stroke: var(--color-text-70);
    }
  }

  .cm--box.cm--wide {
    max-width: 100vw;
  }

  .pm__close-btn {
    border-radius: var(--radius-full);
    border: 1.5px solid var(--color-line);
    background-color: var(--color-white);

    &:hover {
      border-color: var(--color-primary-dark);
      color: var(--color-primary-dark);
      background-color: var(--color-white);
    }
  }

  .pm__section-toggles + .pm__section {
    background-color: var(--color-beige-middle);

    &:hover {
      background-color: var(--color-beige-middle);
    }
  }

  a {
    color: var(--color-secondary-dark);
    text-decoration: underline;

    &:hover {
      background-size: 0;
      color: var(--color-secondary-middle);
    }
  }

  .cm {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 8%);
    border-radius: var(--radius-m);
    background: var(--color-white);

    .cm__body {
      padding: var(--spacing-l) !important;
      border-bottom: 1px solid var(--color-line);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-l);

      .cm__texts {
        padding-top: 0;
        gap: var(--spacing-s);

        & > p,
        & > h2 {
          padding: 0 !important;
          margin: 0 !important;
          color: var(--color-text-100);
        }
      }
    }

    .cm__footer {
      padding: var(--spacing-l) !important;
      display: flex;
      flex-direction: column;
      gap: var(--spacing-l);
      background: var(--color-white);

      .cm__links {
        padding: 0 !important;
      }

      .cm__link-group {
        padding-top: 0;
        justify-content: flex-start !important;
        gap: var(--spacing-l);

        & > a {
          margin-left: 0;
          color: var(--color-text-50);
          font-family: var(--global__font-family_body);
          line-height: 150%;
          font-weight: 400;
          white-space: pre-line;
          text-wrap: pretty;
          hyphens: auto;
          font-size: 0.75rem;
        }
      }
    }

    .cm__btns {
      border-top: none !important;
      padding: 0 !important;
      gap: var(--spacing-s);

      .cm__btn-group {
        gap: var(--spacing-s);
      }
    }
  }

  .pm__btn-group {
    gap: var(--spacing-s);
  }

  .pm__section-title {
    color: var(--color-text-70);
    line-height: 150%;
    white-space: pre-line;
    text-wrap: pretty;
    hyphens: auto;
    font-size: 0.875rem;

    /* font-family: var(--global__font-family_body-semi); TODO: enable */
    font-family: var(--global__font-family_body);
    font-weight: 600;
  }

  .pm__section--toggle {
    border-radius: var(--radius-s);
    border: 1px solid var(--color-line);
    background: var(--color-white);
  }

  .cm__title,
  .pm__title,
  .pm__body > .pm__section:first-child .pm__section-title {
    font-family: var(--font-header);
    font-size: clamp(1.125rem, calc(0.125rem + 0.125vw), 1.25rem);
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    padding: 0 !important;
    margin: 0 !important;
    color: var(--color-text-100);
  }

  .pm__section-desc {
    color: var(--color-text-100);
    font-size: 0.875rem;
  }

  .pm__badge {
    font-style: normal;
    letter-spacing: 0.01em;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-text-70);
    font-family: var(--global__font-family_body);
    line-height: 150%;
    font-weight: 400;
    white-space: pre-line;
    text-wrap: pretty;
    hyphens: auto;
    font-size: 0.75rem;
    background: var(--color-secondary-light);
  }

  .cm__btn,
  .pm__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    font-family: var(--global__font-family_body);
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border: 2px solid transparent;
    border-radius: var(--radius-full);
    appearance: button;
    text-decoration: none;
    letter-spacing: 0.01em;
    padding: 0.6875rem var(--spacing-l);
    font-size: 0.875rem;
    transition: color var(--easing-linear-150), opacity var(--easing-linear-150),
      background-color var(--easing-linear-150),
      border-color var(--easing-linear-150);
    background: var(--color-secondary-light);
    border-color: var(--color-secondary-light);
    color: var(--color-primary-middle);
    margin-left: 0 !important;

    &:hover {
      background: var(--color-secondary-middle);
      border-color: var(--color-secondary-middle);
      color: var(--color-primary-middle);
    }

    &.cm__btn--secondary,
    &.pm__btn--secondary {
      background: transparent;
      border-color: var(--color-line);
      color: var(--color-primary-middle);

      &:hover {
        border-color: var(--color-line-dark);
        color: var(--color-primary-dark);
      }
    }
  }
}

/* Desktop styles */
@media (width >= 1280px) {
  #cc-main {
    .cm--box.cm--wide {
      max-width: 680px;
    }
  }
}
