:root {
  --global__font-family_heading: 'Catamaran', sans-serif;
  --global__font-family_body: 'Courier prime', sans-serif;
  --global__padding_standard: 6rem;
  --global__font-size_base: 16px;
  --footer__height_main: 100vh;
}

html {
  --global__color_primary: #fdfdfd;
  --global__color_primary_opacity: rgba(253, 253, 253, 0.5);
  --global__color_secondary: #2d2d2d;
  --global__color_secondary_opacity: rgba(29, 29, 29, 0.5);
  --footer__color_background: #f3f3f3;
  font-size: var(--global__font-size_base);
}

html.dark-mode {
  --global__color_primary: #1d1d1d;
  --global__color_primary_opacity: rgba(29, 29, 29, 0.5);
  --global__color_secondary: #fdfdfd;
  --global__color_secondary_opacity: rgba(253, 253, 253, 0.5);
  --footer__color_background: #232323;
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

main {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--global__color_secondary);
  font-family: var(--global__font-family_heading);
}

p,
a,
code {
  color: var(--global__color_secondary);
  font-family: var(--global__font-family_body);
}

p + p {
  margin-top: 1rem;
}

a:hover {
  font-weight: bold;
}

body {
  position: relative;
}

text {
  white-space: nowrap;
}

main {
  transition: background-color ease-in-out 200ms;
  background-color: var(--global__color_primary);
  position: relative;
  z-index: 2;
  box-shadow: 0 6px 25px -12px rgba(0, 0, 0, 0.25);
  margin-bottom: var(--footer__height_main);
}

.icon-tabler {
  stroke-width: 1;
  height: 1.8rem;
  width: 1.8rem;
}

aside {
  position: fixed;
  display: none;
  z-index: 10;
  bottom: calc(var(--global__padding_standard) / 2);
  right: var(--global__padding_standard);
  display: flex;
  align-items: center;
}

aside button.language:not(.block) {
  display: none;
}

aside button {
  border: none;
  cursor: pointer;
  padding: 0;
  background: none;
  color: var(--global__color_secondary);
  display: flex;
  justify-content: center;
}

aside button.language .icon-tabler {
  border: 1px solid;
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
}

aside button .icon-tabler:hover {
  stroke-width: 1.5;
  border-width: 1.5px;
}

aside button + button {
  padding-left: 0.5rem;
}

.section-heading {
  padding: 0 var(--global__padding_standard);
  text-transform: uppercase;
  text-align: center;
}

main h2.section-heading {
  padding-top: var(--global__padding_standard);
}

h2.section-heading {
  font-size: 8rem;
}

h3.section-heading {
  font-size: 7rem;
}

section.landing {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: url(../images/background-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1600px) {
  :root {
    --global__padding_standard: 8rem;
    --global__font-size_base: 22px;
  }
}

@media (max-width: 1280px) {
  :root {
    --global__padding_standard: 4rem;
  }
}

@media (max-width: 812px) {
  :root {
    --global__padding_standard: 2rem;
  }

  h2.section-heading {
    font-size: 4rem;
  }

  h3.section-heading {
    font-size: 3rem;
  }

  .icon-tabler {
    height: 1.3rem;
    width: 1.3rem;
  }

  aside button.language .icon-tabler {
    height: 1.1rem;
    width: 1.1rem;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  h2.section-heading {
    font-size: 4rem;
  }

  h3.section-heading {
    font-size: 3rem;
  }
}

/* 
  ##Device = Smallest
*/
@media (max-width: 568px) and (orientation: landscape) {
  :root {
    --global__padding_standard: 1rem;
  }
}

@media (max-width: 360px) {
  :root {
    --global__padding_standard: 1.5rem;
  }

  h2.section-heading {
    font-size: 3rem;
  }

  h3.section-heading {
    font-size: 2rem;
  }

  .icon-tabler {
    height: 1rem;
    width: 1rem;
  }

  aside button.language .icon-tabler {
    height: 0.8rem;
    width: 0.8rem;
  }

  aside button + button {
    padding-left: 0.5rem;
  }
}
