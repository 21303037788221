html.no-js,
html.no-js main {
  height: 100%;
  height: 100vh;
  overflow: hidden;
}

html.no-js button.inverter {
  display: none;
}

.no-js aside {
  display: none;
}

.no-js code.warning {
  color: var(--global__color_secondary);
  background-color: rgba(208, 15, 18, 0.2);
  padding: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0rem;
  z-index: 10;
  font-size: 1.3rem;
}

.no-js code.warning a {
  color: var(--global__color_secondary);
}

.no-js div.get-in-touch {
  position: absolute;
  display: flex;
  align-items: center;
  color: var(--global__color_secondary);
  bottom: calc(var(--global__padding_standard) / 2);
  transform: translateX(-50%);
  left: 50%;
  font-size: 1.2rem;
}

.no-js div.get-in-touch span {
  padding: 0 1rem;
}

.no-js div.get-in-touch p,
.no-js div.get-in-touch a {
  white-space: nowrap;
}
