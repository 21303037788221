:root {
  --portfolio__width_info-text: 55%;
  --portfolio__stroke-width_boxes: 0.5rem;
}

slot.dynamic section {
  width: 100%;
  padding-bottom: var(--global__padding_standard);
  position: relative;
}

slot.dynamic section article {
  position: relative;
  padding: var(--global__padding_standard);
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

slot.dynamic section article h3 {
  transform-origin: left;
  background-position: center center;
  background-clip: text;
  word-break: break-all;
}

slot.dynamic section article h3 div {
  display: inline-block;
}

slot.dynamic figure {
  z-index: 4;
  position: relative;
  overflow: hidden;
  height: 80%;
  width: 70%;
  text-align: center;
  cursor: pointer;
}

slot.dynamic section div:nth-child(even) figure,
slot.dynamic section div:nth-child(even) figure figcaption {
  float: left;
}

slot.dynamic section div:nth-child(even) .info-text {
  left: 40%;
}

slot.dynamic section div:nth-child(odd) figure,
slot.dynamic section div:nth-child(odd) figure figcaption {
  float: right;
}

slot.dynamic section div:nth-child(odd) .info-text {
  left: 5%;
}

slot.dynamic figure img {
  position: absolute;
  display: block;
  max-width: 100%;
  object-fit: cover;
  object-position: center center;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

slot.dynamic figure figcaption {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  z-index: 5;
  text-transform: uppercase;
  font-size: 1.25em;
  backface-visibility: hidden;
  margin: 5%;
  padding: 2.5%;
  width: 30%;
  height: 70%;
  border: var(--portfolio__stroke-width_boxes) solid;
  transition: transform ease-in-out 0.35s;
}

slot.dynamic .info-block {
  z-index: 3;
  position: absolute;
  bottom: calc(var(--global__padding_standard) * 2);
  width: calc(100vw - var(--global__padding_standard) * 2);
  opacity: 1;
  height: 30%;
  left: var(--global__padding_standard);
}

slot.dynamic .info-text {
  font-size: 1.3rem;
  position: absolute;
  bottom: 50%;
  overflow: hidden;
  transform: translateY(50%);
  width: var(--portfolio__width_info-text);
  max-height: 80%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
slot.dynamic figure figcaption > a {
  color: unset;
}

slot.dynamic figure h3 {
  word-spacing: 0em;
  font-weight: 300;
  font-size: 4rem;
  margin: 0;
  text-align: left;
}

slot.dynamic figure h3 span {
  font-weight: 800;
}

slot.dynamic figure figcaption:hover {
  transform: scale(0.99) !important;
}

@media (orientation: portrait) {
  :root {
    --portfolio__width_info-text: 80%;
  }

  slot.dynamic figure img {
    max-width: none;
  }

  slot.dynamic section div:nth-child(even) .info-text,
  slot.dynamic section div:nth-child(odd) .info-text {
    left: 10%;
  }

  slot.dynamic .info-text {
    bottom: 40%;
  }
}

@media (max-width: 1024px) {
  slot.dynamic figure h3 {
    font-size: 3rem;
  }

  slot.dynamic .info-text {
    font-size: 1.2rem;
  }
}

@media (max-width: 1024px) and (orientation: portrait) {
  slot.dynamic figure {
    height: 75%;
    width: 90%;
  }

  slot.dynamic figure figcaption {
    width: 55%;
    margin: 10%;
  }

  slot.dynamic .info-text {
    max-height: 65%;
  }
}

@media (max-width: 768px) and (orientation: portrait) {
  slot.dynamic figure {
    height: 65%;
    width: 80%;
  }

  slot.dynamic figure figcaption {
    width: 55%;
    margin: 10%;
  }
}

@media (max-width: 812px) {
  :root {
    --portfolio__width_info-text: 80%;
    --portfolio__stroke-width_boxes: 0.4rem;
  }

  slot.dynamic figure {
    width: 100%;
    height: 55%;
  }

  slot.dynamic figure img {
    max-width: none;
  }

  slot.dynamic figure figcaption {
    width: 60%;
    margin: 2.5%;
  }

  slot.dynamic section div:nth-child(even) .info-text,
  slot.dynamic section div:nth-child(odd) .info-text {
    left: 10%;
  }

  slot.dynamic figure h3 {
    font-size: 2.5rem;
  }

  slot.dynamic .info-text {
    font-size: 1rem;
  }
}

/* 
  ##Device = Smallest
*/
@media (max-width: 812px) and (orientation: landscape) {
  :root {
    --portfolio__width_info-text: 90%;
  }
  slot.dynamic figure {
    height: 55%;
  }

  slot.dynamic figure figcaption {
    width: 55%;
    height: 55%;
    margin: 2.5% 5%;
  }

  slot.dynamic .info-block {
    height: 40%;
  }

  slot.dynamic .info-text {
    font-size: 1rem;
  }

  slot.dynamic section div:nth-child(even) .info-text,
  slot.dynamic section div:nth-child(odd) .info-text {
    left: 5%;
  }
}

/* 
  ##Device = Smallest
*/
@media (max-width: 568px) and (orientation: landscape) {
  slot.dynamic figure {
    height: 60%;
    width: 80%;
  }

  slot.dynamic .info-block {
    height: 45%;
  }
}

@media (max-width: 480px) {
  slot.dynamic figure {
    height: 60%;
    width: 80%;
  }

  slot.dynamic figure figcaption {
    width: 60%;
    margin: 10%;
  }

  slot.dynamic .info-block {
    height: 55%;
  }
}

@media (max-width: 360px) {
  slot.dynamic figure h3 {
    font-size: 2.2rem;
  }

  slot.dynamic .info-text {
    font-size: 0.9rem;
  }
}
